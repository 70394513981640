import { gettext } from "django-i18n";
import reverse from "django-reverse";
import waffle from "_common/waffle";
import { filterMenuItems } from "./utils";

const ENABLE_SD_BLUEPRINT = waffle.flag_is_active("ENABLE_SD_BLUEPRINT");

const getSystemItems = (payload) => {
  if (!payload.user.isSuperuser) {
    return {
      label: gettext("System"),
      items: [
        {
          label: gettext("Integration"),
          link: reverse("system_integration_redirect"),
          perm: (perms) => perms.editSystemConnections,
        },
        {
          label: gettext("Automations"),
          link: reverse("automations"),
          perm: (perms) => perms.editAutomations,
        },
        {
          label: gettext("Features"),
          link: reverse("system_features"),
          perm: (perms) => perms.manageFeatures,
        },
      ],
    };
  }

  const children = [
    {
      label: gettext("Domain Settings"),
      link: reverse("domain_settings"),
    },
    {
      label: gettext("Authentication"),
      link: reverse("sso_settings"),
      feature: (features) => features.hasSSOFeature,
    },
    {
      label: gettext("Theming"),
      link: reverse("system_theming"),
    },
    {
      label: gettext("Countermeasure Status"),
      link: reverse("task_status_list"),
    },
    {
      label: gettext("Custom Attributes"),
      link: reverse("custom_attributes"),
    },
    {
      label: gettext("Integration"),
      link: reverse("system_integration_redirect"),
      perm: (perms) => perms.editSystemConnections,
    },
    {
      label: gettext("UI Customization"),
      link: reverse("ui_customization"),
    },
    {
      label: gettext("Jobs"),
      link: reverse("jobs_list"),
    },
    {
      label: gettext("Automations"),
      link: reverse("automations"),
      perm: (perms) => perms.editAutomations,
    },
    {
      label: gettext("Features"),
      link: reverse("system_features"),
      perm: (perms) => perms.manageFeatures,
    },
  ];

  if (payload.DEBUG) {
    children.push({
      label: gettext("Admin Panel"),
      link: reverse("admin:index"),
      target: "_blank",
    });
  }

  return ENABLE_SD_BLUEPRINT
    ? {}
    : {
        label: gettext("System"),
        link: reverse("system_root"),
        items: children,
      };
};

const getSupportItems = (payload) => {
  const { releaseShortName, releaseSlug } = payload;

  const children = [
    {
      label: gettext("Release Notes"),
      link: `https://docs.sdelements.com/release/latest/guide/docs/releasenotes.html#${releaseSlug}`,
      target: "_blank",
      icon: "open",
    },
    {
      label: gettext("Third Party Attribution"),
      link: reverse("third_party_attribution"),
    },
    { divider: true },
    {
      label: gettext("Documentation"),
      link: `https://docs.sdelements.com/release/${releaseShortName}`,
      target: "_blank",
      icon: "open",
    },
    {
      label: gettext("Privacy Policy"),
      link: payload.PRIVACY_POLICY_LINK,
      target: "_blank",
      icon: "open",
    },
    {
      label: gettext("Contact Us"),
      link: `mailto:${payload.FEEDBACK_EMAIL}`,
      target: "_self",
      icon: "mail",
    },
  ];

  return {
    label: gettext("Help & Support"),
    items: children,
  };
};

const logoutItem = {
  label: gettext("Logout"),
  link: reverse("account_logout"),
  target: "_self",
};

const getAccountItems = (payload) => {
  let children = [
    {
      label: gettext("Account"),
      link: reverse("account_settings"),
      target: "_self",
    },
    {
      label: gettext("Notifications"),
      link: reverse("notification_settings"),
      target: "_self",
    },
  ];

  if (payload.TRAINING_LICENSE) {
    children.push({
      label: gettext("Training"),
      link: reverse("training_profile"),
    });
  }

  if (!ENABLE_SD_BLUEPRINT) {
    children = children.concat([
      {
        label: gettext("API"),
        link: reverse("api_settings"),
        target: "_self",
      },
    ]);
  }

  children = children.concat([{ divider: true }, logoutItem]);

  return {
    label: payload.user.first_name || gettext("Account"),
    link: reverse("account_settings"),
    target: "_self",
    perm: (perms) => perms.modifySelf,
    items: children,
  };
};

const getNonAccountItems = (payload) => {
  const children = [
    {
      label: gettext("Account"),
      link: reverse("account_settings"),
      target: "_self",
    },
    { divider: true },
    logoutItem,
  ];

  return {
    label: payload.user.first_name || gettext("Account"),
    link: reverse("account_settings"),
    target: "_self",
    perm: (perms) => perms.cantModifySelf,
    items: children,
  };
};

export default (payload = { features: [], globalPerms: [], user: {} }) => ({
  system: filterMenuItems(getSystemItems(payload), payload),
  support: filterMenuItems(getSupportItems(payload), payload),
  account: filterMenuItems(getAccountItems(payload), payload),
  nonaccount: filterMenuItems(getNonAccountItems(payload), payload),
});
