import { createLogic } from "redux-logic";
import reverse from "django-reverse";
import storejs from "store";
import { locationChanged } from "_common2/components/Router";

import highlights from "_common/components/highlight/highlights";
import { setSeenHighlights } from "../actions";

const LOCALSTORAGE_KEY = "highlights.seen";

export const syncHighlightsLogic = createLogic({
  type: locationChanged,
  latest: true,

  process({ action }, dispatch, done) {
    const { payload: location } = action;
    let seen = storejs.get(LOCALSTORAGE_KEY);

    if (!Array.isArray(seen)) {
      seen = [];
    }

    const newHighlight = highlights
      .filter((highlight) => !seen.includes(highlight))
      .find((highlight) => reverse(highlight) === location.pathname);

    if (newHighlight) {
      seen = [...seen, newHighlight];
    }

    dispatch(setSeenHighlights(seen));

    storejs.set(LOCALSTORAGE_KEY, seen);

    done();
  },
});
