import BaseService from "_common/BaseService";

/**
 * Handles prefernces endpoint.
 */
class PreferencesService extends BaseService {
  constructor() {
    super();
    this.url = "preferences/";
  }

  updatePreferences(query = {}) {
    return this.update(null, query, this.url);
  }
}

export default new PreferencesService();
