import { createLogic } from "redux-logic";

import { makeDjangoToast } from "_common/components/toast/actions";
import { init } from "../actions";

export const initLogic = createLogic({
  type: init,
  latest: true,

  process({ action }, dispatch, done) {
    const { messages = [] } = action.payload || {};

    messages.forEach(([type, text, extraTags]) =>
      dispatch(makeDjangoToast(type, text, extraTags))
    );

    done();
  },
});
