import { clearAllIntents } from "../actions";

export default (state = {}, action) => {
  const { type, payload } = action;

  if (type === clearAllIntents.toString()) {
    return {};
  }

  if (!type.match(/_INTENT$/)) {
    return state;
  }

  const { name, value } = payload;

  return {
    ...state,
    [name]: value,
    [type]: value,
  };
};
