import BaseService from "../BaseService";

/**
 * Handles members endpoint.
 */
class MembersService extends BaseService {
  constructor() {
    super();
    this.url = "members/";
  }

  getMembers(query = {}) {
    return this.list(
      {
        page_size: query.pageSize,
        page: query.page,
        search: query.search,
        business_unit: query.businessUnit,
        project: query.project,
        exclude_user: query.exclude_user || [],
        exclude_group: query.exclude_group || [],
        users: query.users,
        groups: query.groups,
      },
      this.url
    );
  }
}

export default new MembersService();
