import BaseService from "../BaseService";

/**
 * Retrieves recent nav items.
 */
class NavTreeService extends BaseService {
  constructor() {
    super();
    this.url = "recent/";
  }

  getNavTree() {
    return this.axios.get(this.getBaseUrl(), {
      params: { max_items_each: 4 },
    });
  }
}

export default new NavTreeService();
