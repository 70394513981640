import { redirectLogic, setCookieLogic } from "./general";
import { syncHighlightsLogic } from "./highlights";
import { initLogic } from "./init";
import { fetchMembersLogic } from "./members";
import {
  fetchGlobalSearchSuggestionsLogic,
  updateNavTreeLogic,
} from "./navigation";
import { editPreferencesLogic } from "./preferences";
import {
  fetchBusinessUnitSuggestionsLogic,
  fetchApplicationSuggestionsLogic,
  fetchLibraryTaskSuggestionsLogic,
  fetchTaskStatusSuggestionsLogic,
} from "./suggestions";
import { createSupportRequestLogic } from "./supportRequest";
import { fetchTagSuggestionsLogic } from "./tags";

const logics = [
  initLogic,
  redirectLogic,
  setCookieLogic,

  syncHighlightsLogic,

  fetchMembersLogic,

  fetchGlobalSearchSuggestionsLogic,
  updateNavTreeLogic,

  editPreferencesLogic,

  fetchBusinessUnitSuggestionsLogic,
  fetchApplicationSuggestionsLogic,
  fetchLibraryTaskSuggestionsLogic,
  fetchTaskStatusSuggestionsLogic,

  createSupportRequestLogic,

  fetchTagSuggestionsLogic,
];

export default logics;
