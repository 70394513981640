export default {
  _archiveProject: "archive_project",

  editProjectMembership: "edit_project_membership",
  assignTask: "assign_task",
  markTask: "mark_task",
  createProjectSpecificTask: "create_project_specific_task",
  editProjectDetails: "edit_project_details",
  lockProject: "lock_project",
  lockProjectSurvey: "lock_project_survey",
  verifyTask: "verify_task",
  viewProject: "view_project",
  writeTaskNote: "write_task_note",
  syncWithIssueTracker: "sync_with_issue_tracker",
  editProjectSurvey: "edit_project_survey",

  archiveProject: (perms, projPerms) =>
    perms.editAllProjects || projPerms._archiveProject,
};
