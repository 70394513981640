// Polyfill for diagrams in Safari
// Necessary for Diagrams / Cytoscape
// See: https://github.com/iVis-at-Bilkent/cytoscape.js-context-menus/issues/55
import "@ungap/custom-elements";

import i18n from "django-i18n";

import React from "react";
import { render } from "react-dom";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { createLogger } from "redux-logger";
import createReduxWaitForMiddleware from "redux-wait-for-action";
import { createLogicMiddleware } from "redux-logic";
import BodyClassName from "react-body-classname";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { TunnelProvider } from "react-tunnels";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { SnackbarProvider } from "notistack";

import LegacyThemeProvider from "@deprecated/material-ui/styles/MuiThemeProvider";
import RenderToLayer from "@deprecated/material-ui/internal/RenderToLayer";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

import { init } from "_common/actions";
import { IBootstrapperOptions } from "_common/interfaces";
import logics from "_common/logics";
import legacyTheme from "_common/theme";

import Toaster from "_common2/components/Toaster";
import { theme } from "_common2/theme";

import Router, {
  history,
  locationChanged,
  routerLogics,
} from "_common2/components/Router";

import "../../scss/style.scss";
import { queryClient } from "./queryClient";

const showDevTools =
  process.env.NODE_ENV === "development" &&
  process.env.FRONTEND_SHOW_REACT_QUERY_DEVTOOLS === "true";

i18n.setUiCustomizations({
  source: window.django.UI_STRS,
  terms: window.django.inflected_terms || [],
});

const logicMiddleware = createLogicMiddleware();

export default (options: IBootstrapperOptions) => {
  const store = configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Prior to migrating to redux-toolkit,
          // some of our actions and reducers contained
          // non-serializable data (such as functions)
          ignoreActions: true,
          ignoredPaths: ["django", "navigation", "toast"],
        },
      }).concat([
        createReduxWaitForMiddleware(),
        logicMiddleware,
        createLogger({
          collapsed: true,
        }),
      ]),
    reducer: options.reducer,
  });

  history.listen((location) => {
    store.dispatch(locationChanged(location));
  });

  logicMiddleware.addLogic(routerLogics);
  logicMiddleware.addLogic(logics);

  if (options.logics) {
    logicMiddleware.addLogic(options.logics);
  }

  store.dispatch(init(window.django));

  if (options.initAction) {
    const { payload } = options.initAction;
    store.dispatch(options.initAction.action(payload));
  }

  // IE class shim
  // This adds a class to the body element allowing us to
  // write CSS/SASS selectors targeting IE 10 and under.
  // Note: HTML conditionals no longer work as of IE 10
  const { documentMode } = document as Document;
  const classes = (documentMode && documentMode < 11 && "ie-lt-11") || "";

  // MUI v0 RenderToLayer ignores React.Context API,
  // So we configure a wrapper that redefines some context
  RenderToLayer.Wrapper = ({ children }: React.PropsWithChildren<{}>) => (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );

  render(
    <BodyClassName className={classes}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LegacyThemeProvider muiTheme={legacyTheme}>
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  maxSnack={3}
                  preventDuplicate
                  style={{
                    // Override pointer-events,
                    // to ensure toasts are visible to Cypress
                    pointerEvents: "auto",
                    wordBreak: "break-all",
                  }}
                >
                  <BreadcrumbsProvider>
                    <Router>
                      <TunnelProvider>
                        <>
                          {options.rootComponent}
                          <Toaster />
                        </>
                      </TunnelProvider>
                    </Router>
                  </BreadcrumbsProvider>
                </SnackbarProvider>
              </LegacyThemeProvider>
            </ThemeProvider>
          </StyledEngineProvider>
          {showDevTools && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </Provider>
    </BodyClassName>,
    document.getElementById("mount")
  );
};
