import BaseService from "../BaseService";

/**
 * Handles suggestions/search requests.
 */
class SuggestionsService extends BaseService {
  _get(params, url) {
    return this.list(params, url).then((response) => response.data.results[0]);
  }

  get(params, url) {
    return super.get(params, url).then((response) => response.data);
  }

  list(params, url) {
    return super.list(
      {
        page: 1,
        ...params,
      },
      url
    );
  }

  getBusinessUnits({ maxOptions = 10, term = "" }) {
    return this.list(
      {
        name__icontains: term,
        ordering: "name",
        page_size: maxOptions,
      },
      "business-units/"
    );
  }

  getBusinessUnit({ id }) {
    return this.get({}, `business-units/${id}/`);
  }

  getApplications({ businessUnit, maxOptions = 10, term = "" }) {
    return this.list(
      {
        business_unit: businessUnit || "",
        expand: "business_unit",
        name__icontains: term,
        ordering: "name",
        page_size: maxOptions,
      },
      "applications/"
    );
  }

  getApplication({ id }) {
    return this.get({ expand: "business_unit" }, `applications/${id}/`);
  }

  getLibraryTasks({ maxOptions = 10, term = "" }) {
    return this.list({ page_size: maxOptions, search: term }, "library/tasks/");
  }

  getLibraryTask({ id }) {
    return this.get({}, `library/tasks/${id}/`);
  }

  getTaskStatuses({ maxOptions = 10, term = "" }) {
    return this.list(
      { name__icontains: term, page_size: maxOptions },
      "task-statuses/"
    );
  }

  getTaskStatus({ id }) {
    return this.list({ id }, "task-statuses/").then(
      (response) => response.data.results[0]
    );
  }
}

export default new SuggestionsService();
