import { createReducer } from "@reduxjs/toolkit";

import { fetchMembers, init } from "../actions";

const initialState = {
  loadingMembers: false,
  items: [],
  defaultRole: "",
  roles: [],
};

export default createReducer(initialState, {
  [init]: (state, { payload }) => ({
    ...state,
    defaultRole: payload.defaultProjectRoleId || "",
    roles: payload.projectRoles || [],
  }),

  [fetchMembers.request]: (state) => ({
    ...state,
    loadingMembers: true,
  }),

  [fetchMembers.complete]: (state) => ({
    ...state,
    loadingMembers: false,
  }),

  [fetchMembers.success]: (state, action) => ({
    ...state,
    items: action.payload.members,
  }),
});
