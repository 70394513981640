import { createReducer } from "@reduxjs/toolkit";

import { addToast, removeToast } from "./actions";

const initialState = {
  toasts: [],
};

export default createReducer(initialState, {
  [addToast]: (state, action) => ({
    ...state,
    toasts: state.toasts.concat([action.payload]),
  }),

  [removeToast]: (state, { payload: id }) => ({
    ...initialState,
    toasts: state.toasts.filter((toast) => toast.id !== id),
  }),
});
