import globalPerms from "./globalPerms";
import projectPerms from "./projectPerms";

import createChecker from "../utils/staticModelChecker";

function createPermCheckers(rawPerms, rawProjectPerms) {
  const globalChecker = createChecker(globalPerms, rawPerms);

  let projChecker = () => false;

  if (rawProjectPerms && rawProjectPerms.length) {
    projChecker = createChecker(projectPerms, rawProjectPerms, globalChecker);
  }

  return [globalChecker, projChecker];
}

export default (usersGlobalPerms, usersProjectPerms) => {
  const permCheckers = createPermCheckers(usersGlobalPerms, usersProjectPerms);

  return function isPermitted(requiredPermission = () => true) {
    return requiredPermission(...permCheckers);
  };
};
