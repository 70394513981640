import { createReducer } from "@reduxjs/toolkit";

import { fetchTagSuggestions } from "../actions";

export default createReducer(
  {
    suggestions: [],
  },
  {
    [fetchTagSuggestions.success]: (state, action) => ({
      ...state,
      suggestions: action.payload,
    }),
  }
);
