export default {
  addBusinessUnit: "create_business_unit",
  deleteBusinessUnit: "delete_business_unit",
  addApplication: "add_application",
  deleteApplication: "delete_application",
  archiveApplication: "archive_application",
  moveApplication: (perms) => perms.addApplication && perms.deleteApplication,

  addProject: "add_project",
  deleteProject: "delete_project",
  viewAllProjects: "view_all_project",
  editAllProjects: "admin_all_project",
  moveProject: (perms) => perms.addProject && perms.deleteProject,

  editProjectDetails: "edit_project_details",

  addUser: "add_user",
  modifySelf: "modify_self",

  cantModifySelf: (perms) => !perms.modifySelf,

  editIssueTrackerConnections: "edit_issue_tracker_connections",
  editAnalysisConnections: "edit_analysis_connections",
  editProjectConnections: "edit_project_connections",

  editSystemConnections: (perms) =>
    perms.editIssueTrackerConnections ||
    perms.editAnalysisConnections ||
    perms.editProjectConnections,

  editAutomations: "admin_all_automations",
  manageFeatures: "manage_features",

  editReports: "edit_reports",
  customizeContent: "customize_content",
  manageRiskPolicies: "manage_risk_policies",
  viewAdvancedReports: "view_analytics",
  editDeleteAdvancedReports: "edit_delete_advanced_reports",
  editDeleteDashboards: "edit_delete_dashboards",
  // Context Perms
  reportingContextAll: "reporting_context__all",
  reportingContextIntegration: "reporting_context_integration",
  reportingContextProjectSurveyAnswers:
    "reporting_context_project_survey_answers",
  reportingContextApplication: "reporting_context_application",
  reportingContextUser: "reporting_context_user",
  reportingContextActivity: "reporting_context_activity",
  reportingContextCountermeasure: "reporting_context_countermeasure",
  reportingContextLibrary: "reporting_context_library",
  reportingContextTraining: "reporting_context_training",
  trendContextApplication: "trend_reporting_context_application",
  trendContextCountermeasure: "trend_reporting_context_countermeasure",
  trendContextProject: "trend_reporting_context_project",
};
