import toast from "_common/components/toast/reducer";

import highlights from "./highlights";
import theming from "./theming";
import django from "./django";
import members from "./members";
import navigation from "./navigation";
import globalSearch from "./navigation/globalSearch";
import tags from "./tags";
import intents from "./intents";
import suggestions from "./suggestions";

export default {
  django,
  theming,
  members,
  navigation,
  globalSearch,
  tags,
  toast,
  intents,
  highlights,
  suggestions,
};
