import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import {
  fetchBusinessUnitSuggestions,
  fetchApplicationSuggestions,
  fetchLibraryTaskSuggestions,
  fetchTaskStatusSuggestions,
} from "../actions";

const createSuggestionsReducer = (action) =>
  createReducer(
    {
      loading: false,
      suggestions: [],
    },
    {
      [action.request]: (state) => ({
        ...state,
        loading: true,
      }),

      [action.complete]: (state) => ({
        ...state,
        loading: false,
      }),

      [action.success]: (state, action) => ({
        ...state,
        suggestions: action.payload.suggestions,
      }),
    }
  );

export default combineReducers({
  businessUnit: createSuggestionsReducer(fetchBusinessUnitSuggestions),
  application: createSuggestionsReducer(fetchApplicationSuggestions),
  libraryTask: createSuggestionsReducer(fetchLibraryTaskSuggestions),
  taskStatus: createSuggestionsReducer(fetchTaskStatusSuggestions),
});
