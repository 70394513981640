import { createReducer } from "@reduxjs/toolkit";

import { setSeenHighlights } from "../actions";

const initialState = {
  seen: [],
};

export default createReducer(initialState, {
  [setSeenHighlights]: (state, action) => ({
    ...state,
    seen: action.payload,
  }),
});
