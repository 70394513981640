import { createLogic } from "redux-logic";
import { push, replace } from "./actions";
import { history } from "./history";

export const pushLogic = createLogic({
  type: push,
  latest: true,

  process({ action }, dispatch, done) {
    history.push(action.payload);
    done();
  },
});

export const replaceLogic = createLogic({
  type: replace,
  latest: true,

  process({ action }, dispatch, done) {
    history.replace(action.payload);
    done();
  },
});

export const routerLogics = [pushLogic, replaceLogic];
