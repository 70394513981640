import features from "./features";

import createChecker from "../utils/staticModelChecker";

export default (orgFeatures) => {
  const featureChecker = createChecker(features, orgFeatures);

  return function isPermitted(requiredFeature = () => true) {
    return requiredFeature(featureChecker);
  };
};
