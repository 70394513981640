import BaseService from "../BaseService";

/**
 * Handles support request requests (hehe).
 */
class SupportRequestService extends BaseService {
  constructor() {
    super();
    this.url = "support-request/";
  }

  sendSupportRequest(data) {
    return this.create(data);
  }
}

export default new SupportRequestService();
