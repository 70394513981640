import { createReducer } from "@reduxjs/toolkit";

import { init, updateDjangoContext } from "../../actions";
import getMainNavItems from "./main";
import getUserNavItems from "./user";

const getNavItems = (payload) => ({
  main: getMainNavItems(payload),
  user: getUserNavItems(payload),
});

export default createReducer(getNavItems(), {
  [init]: (state, action) => getNavItems(action.payload),
  [updateDjangoContext]: (state, action) => getNavItems(action.payload),
});
