import { createLogic } from "redux-logic";

import { apiRequest } from "_common/logic";
import { editPreferences } from "../actions";

import PreferencesService from "../services/PreferencesService";

export const editPreferencesLogic = createLogic({
  type: editPreferences,
  latest: true,

  async process({ action }, dispatch, done) {
    const { payload } = action;
    await apiRequest({
      dispatch,
      action: editPreferences,
      fn: () => PreferencesService.updatePreferences(payload),
    });

    done();
  },
});
