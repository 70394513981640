import { createLogic } from "redux-logic";

import { apiRequest } from "_common/logic";
import { fetchMembers } from "../actions";

import MembersService from "../services/MembersService";

export const fetchMembersLogic = createLogic({
  type: fetchMembers,
  latest: true,

  async process({ action }, dispatch, done) {
    const { query } = action.payload;

    await apiRequest({
      dispatch,
      action: fetchMembers,
      fn: () =>
        MembersService.getMembers({
          page: 1,
          pageSize: 16,
          ...query,
        }),

      resultMap: (response) => ({
        members: response.results,
        totalItemCount: response.count,
      }),
    });

    done();
  },
});
