export default {
  // Application features
  businessUnit: "business_unit",

  manualScanningIntegration: "manual_scanning_integration",
  automatedScanningIntegration: "automated_scanning_integration",
  projectCreationIntegration: "project_creation_integration",
  advancedIssueTracker: "advanced_issue_tracker",

  // Library features
  libraryImport: "library_import",
  libraryExport: "library_export",
  complianceRegulations: "compliance_regulations",

  // SSO features
  ssoLDAP: "sso_ldap",
  ssoSAML: "sso_saml",
  ssoHTTP: "sso_http",
  hasSSOFeature: (features) =>
    features.ssoLDAP || features.ssoSAML || features.ssoHTTP,
};
