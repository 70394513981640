import { isEmpty } from "lodash";
import checkFeature from "_common/features";
import checkPerm from "_common/perms";

const isVisible = (item, { globalPerms, features }) => {
  if (item.feature && !checkFeature(features)(item.feature)) {
    return false;
  }
  if (item.perm && !checkPerm(globalPerms)(item.perm)) {
    return false;
  }
  return true;
};

export const filterMenuItems = (rootItem, { globalPerms, features }) => {
  const rootIsVisible = isVisible(rootItem, { globalPerms, features });

  if (!rootIsVisible) {
    return undefined;
  }

  const children = (rootItem.items || []).filter((item) =>
    isVisible(item, { globalPerms, features })
  );

  if (!rootItem.link && isEmpty(children)) {
    return undefined;
  }

  return {
    ...rootItem,
    items: children,
  };
};
