import { createAction } from "@reduxjs/toolkit";
import { DjangoContext } from "../interfaces";
import { createApiAction } from "./utilities";

export const clearAllIntents = createAction<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  undefined | { [extraProps: string]: any }
>("CLEAR_ALL_INTENTS");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const editPreferences = createApiAction<{ [extraProps: string]: any }>(
  "EDIT_PREFERENCES"
);

export const init = createAction<DjangoContext>("GLOBAL_INIT");

export const fetchMembers = createApiAction(
  "FETCH_MEMBERS",
  ({ query } = {}) => ({ payload: { query } })
);

export const createSupportRequest = createApiAction<{
  name: string;
  email: string;
  subject: string;
  description: string;
}>("CREATE_SUPPORT_REQUEST");

export const updateNavTree = createApiAction("UPDATE_NAV_TREE");

export const updateDjangoContext = createAction<DjangoContext>(
  "UPDATE_DJANGO_CONTEXT"
);

export const redirect = createAction<string>("REDIRECT");

export const setCookie = createAction(
  "SET_COOKIE",
  (key: string, value: string) => ({
    payload: {
      key,
      value,
    },
  })
);

export const fetchTagSuggestions = createApiAction<string>(
  "FETCH_TAG_SUGGESTIONS"
);

export const fetchGlobalSearchSuggestions = createApiAction<string>(
  "FETCH_PROJECT_SUGGESTIONS"
);

export const fetchBusinessUnitSuggestions = createApiAction<{ query: string }>(
  "FETCH_BU_SUGGESTIONS"
);

export const fetchApplicationSuggestions = createApiAction<{ query: string }>(
  "FETCH_APP_SUGGESTIONS"
);

export const fetchLibraryTaskSuggestions = createApiAction<{ query: string }>(
  "FETCH_LIBRARY_TASK_SUGGESTIONS"
);

export const fetchTaskStatusSuggestions = createApiAction<{ query: string }>(
  "FETCH_TASK_STATUS_SUGGESTIONS"
);

export const setSeenHighlights = createAction<string[]>("SET_SEEN_HIGHLIGHTS");
