import { createReducer } from "@reduxjs/toolkit";

import { init, editPreferences, updateDjangoContext } from "../actions";

export default createReducer(
  {},
  {
    [init]: (state, action) => action.payload,
    [updateDjangoContext]: (state, action) => action.payload,
    [editPreferences.success]: (state, action) => ({
      ...state,
      uiPreferences: action.payload,
    }),
  }
);
