import BaseService from "_common/BaseService";

/**
 * Handles tags endpoint.
 */
class ProjectService extends BaseService {
  constructor() {
    super();
    this.url = "tags/";
  }

  getTags(query = {}) {
    const { pageSize, ...rest } = query;

    return this.list({
      ...rest,
      page_size: pageSize,
    });
  }
}

export default new ProjectService();
