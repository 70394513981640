import { createLogic } from "redux-logic";

import { apiRequest } from "_common/logic";
import {
  fetchGlobalSearchSuggestions,
  updateDjangoContext,
  updateNavTree,
} from "../actions";

import NavTreeService from "../services/NavTreeService";
import GlobalSearchSuggestionService from "../services/GlobalSearchSuggestionService";

export const updateNavTreeLogic = createLogic({
  type: updateNavTree,
  latest: true,

  async process({ getState }, dispatch, done) {
    const context = getState().django;
    const data = await apiRequest({
      dispatch,
      action: updateNavTree,
      fn: () => NavTreeService.getNavTree(),
      resultMap: (response) => ({
        ...context,
        recentNavTree: response.results,
      }),
    });

    if (data) {
      dispatch(updateDjangoContext(data));
    }

    done();
  },
});

export const fetchGlobalSearchSuggestionsLogic = createLogic({
  type: fetchGlobalSearchSuggestions,
  latest: true,

  async process({ action }, dispatch, done) {
    const term = action.payload;
    await apiRequest({
      dispatch,
      action: fetchGlobalSearchSuggestions,
      fn: () =>
        GlobalSearchSuggestionService.getSuggestions({
          max_items_each: 10,
          search: term,
        }),
      resultMap: (response) => ({
        term,
        suggestions: response.results,
      }),
    });

    done();
  },
});
