import getMuiTheme from "@deprecated/material-ui/styles/getMuiTheme";

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: "rgb(255,193,7)",
  },
  spacing: {
    desktopGutterLess: "8px", // Used for flat button label padding
  },
  floatingActionButton: {
    buttonSize: "48px",
    miniSize: "36px",
    color: "rgb(255,193,7)",
    iconColor: "rgba(0,0,0,0.87)",
    disabledColor: "rgb(186,186,186)",
  },
  svgIcon: {
    color: "currentColor",
  },
  radioButton: {
    labelColor: "rgba(0,0,0,0.7)",
    borderColor: "rgba(0,0,0,0.54)",
  },
  checkbox: {
    labelColor: "rgba(0,0,0,0.7)",
    boxColor: "rgba(0,0,0,0.54)",
  },
  snackbar: {
    actionColor: "rgb(255,193,7)",
  },
  menuItem: {
    hoverColor: "rgba(0,0,0,0.08)",
    selectedTextColor: "rgb(183,28,28)",
  },
  datePicker: {
    color: "rgb(29,129,66)",
    textColor: "rgba(0,0,0,0.87)",
    selectColor: "rgb(255,193,7)",
    selectTextColor: "rgba(0,0,0,0.87)",
    headerColor: "rgb(255,193,7)",
  },
  textField: {
    disabledTextColor: "rgba(0,0,0,0.38)",
    floatingLabelColor: "rgba(0,0,0,0.6)",
    hintColor: "rgba(0,0,0,0.6)",
  },
  zIndex: {
    dialog: 1300,
    dialogOverlay: 1250,
  },
});

export default muiTheme;
