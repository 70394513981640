import { createLogic } from "redux-logic";
import { gettext } from "django-i18n";

import { apiRequest } from "_common/logic";
import { createSupportRequest } from "../actions";

import SupportRequestService from "../services/SupportRequestService";

import { makeToast } from "../components/toast/actions";

export const createSupportRequestLogic = createLogic({
  type: createSupportRequest,
  latest: true,

  async process({ action }, dispatch, done) {
    const data = action.payload;
    const response = await apiRequest({
      dispatch,
      action: createSupportRequest,
      fn: () => SupportRequestService.sendSupportRequest(data),
      form: true,
    });

    if (!response) {
      return done();
    }

    dispatch(makeToast("success", gettext("Support request sent.")));

    done();
  },
});
