import diff from "object-diff";
import { isEmpty, isEqual } from "lodash";
import axios from "./axios";

/**
 * The template service all other API services are based on.
 */
class BaseService {
  constructor() {
    this.axios = axios;
  }

  getBaseUrl() {
    if (this.url) {
      return this.url;
    }
    throw new Error('Service must define "url" in it\'s constructor.');
  }

  getItemUrl(id) {
    const baseUrl = this.getBaseUrl();
    return `${baseUrl}${id}/`;
  }

  list(params = {}, url) {
    const getUrl = url || this.getBaseUrl();
    return this.axios.get(getUrl, {
      params,
    });
  }

  get(params = {}, url, id) {
    const getUrl = url || this.getItemUrl(id);

    return this.axios.get(getUrl, {
      params,
    });
  }

  create(body = {}, url, params = {}) {
    const postUrl = url || this.getBaseUrl();

    return this.axios.post(postUrl, body, { params });
  }

  update(initialValues, body, url, params = {}, force = false) {
    const getUrl = url || this.getItemUrl(initialValues.id);
    const patch = diff.custom(
      { equal: isEqual },
      initialValues || {},
      body || {}
    );

    if (!force && isEmpty(patch)) {
      return this.axios.get(getUrl, {
        params,
      });
    }

    return this.axios.patch(getUrl, patch, { params });
  }

  put(body, url, params = {}) {
    const getUrl = url;

    return this.axios.put(getUrl, body, { params });
  }

  delete(id, url, data = {}) {
    const deleteUrl = url || this.getItemUrl(id);

    return this.axios.delete(deleteUrl, { data });
  }
}

export default BaseService;
