function assignToObject(source, dest, map = (value) => value) {
  const props = Object.keys(source).map((key) => [key, map(source[key])]);

  return props.reduce((obj, currentArray) => {
    const key = currentArray[0];
    const value = currentArray[1];
    Object.defineProperty(obj, key, {
      get: value,
    });
    return obj;
  }, dest);
}

function createChecker(staticModels, rawStaticModels = [], ...otherCheckers) {
  const checker = {
    includes: (str) => {
      const strs = Array.isArray(str) ? str : [str];
      return strs.every((s) => rawStaticModels.includes(s));
    },
  };

  assignToObject(staticModels, checker, (value) => {
    if (typeof value === "function") {
      return () => value(...otherCheckers, checker);
    }

    return () => rawStaticModels.includes(value);
  });

  return checker;
}

export default createChecker;
