import { createLogic } from "redux-logic";
import cookie from "js-cookie";

import { setCookie, redirect } from "../actions";

export const setCookieLogic = createLogic({
  type: setCookie,
  latest: true,

  process({ action }, dispatch, done) {
    const { payload = {} } = action;
    const { key, value } = payload;
    cookie.set(key, value, { path: "/" });

    done();
  },
});

export const redirectLogic = createLogic({
  type: redirect,
  latest: true,

  process({ action }, dispatch, done) {
    window.location = action.payload;

    done();
  },
});
