import { createLogic } from "redux-logic";

import { apiRequest } from "_common/logic";
import SuggestionsService from "../services/SuggestionsService";
import {
  fetchBusinessUnitSuggestions,
  fetchApplicationSuggestions,
  fetchLibraryTaskSuggestions,
  fetchTaskStatusSuggestions,
} from "../actions";

const resultMap = (response) => ({
  suggestions: response.results ? response.results : [response],
  count: response.results ? response.count : 1,
});

export const fetchBusinessUnitSuggestionsLogic = createLogic({
  type: fetchBusinessUnitSuggestions,
  latest: true,

  async process({ action }, dispatch, done) {
    const query = action.payload;
    await apiRequest({
      dispatch,
      action: fetchBusinessUnitSuggestions,
      fn: () => SuggestionsService.getBusinessUnits(query),
      resultMap,
    });

    done();
  },
});

export const fetchApplicationSuggestionsLogic = createLogic({
  type: fetchApplicationSuggestions,
  latest: true,

  async process({ action }, dispatch, done) {
    const query = action.payload;
    await apiRequest({
      dispatch,
      action: fetchApplicationSuggestions,
      fn: () => SuggestionsService.getApplications(query),
      resultMap,
    });

    done();
  },
});

export const fetchLibraryTaskSuggestionsLogic = createLogic({
  type: fetchLibraryTaskSuggestions,
  latest: true,

  async process({ action }, dispatch, done) {
    const query = action.payload;
    await apiRequest({
      dispatch,
      action: fetchLibraryTaskSuggestions,
      fn: () => SuggestionsService.getLibraryTasks(query),
      resultMap,
    });

    done();
  },
});

export const fetchTaskStatusSuggestionsLogic = createLogic({
  type: fetchTaskStatusSuggestions,
  latest: true,

  async process({ action }, dispatch, done) {
    const query = action.payload;
    await apiRequest({
      dispatch,
      action: fetchTaskStatusSuggestions,
      fn: () => SuggestionsService.getTaskStatuses(query),
      resultMap,
    });

    done();
  },
});
