import BaseService from "_common/BaseService";

/**
 * Handles global search endpoint.
 */
class GlobalSearchSuggestionService extends BaseService {
  constructor() {
    super();
    this.url = "global-search/";
  }

  getSuggestions(query = {}) {
    return this.list(query);
  }
}

export default new GlobalSearchSuggestionService();
