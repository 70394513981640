import { createLogic } from "redux-logic";

import { apiRequest } from "_common/logic";
import TagService from "../services/TagService";

import { fetchTagSuggestions } from "../actions";

export const fetchTagSuggestionsLogic = createLogic({
  type: fetchTagSuggestions,
  latest: true,

  async process({ action }, dispatch, done) {
    const term = action.payload;
    await apiRequest({
      dispatch,
      action: fetchTagSuggestions,
      fn: () => TagService.getTags({ name__istartswith: term }),
    });

    done();
  },
});
