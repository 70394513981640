import { createReducer } from "@reduxjs/toolkit";

import { fetchGlobalSearchSuggestions } from "../../actions";

export default createReducer(
  {
    suggestions: [],
    term: "",
  },
  {
    [fetchGlobalSearchSuggestions.reset]: (state) => ({
      ...state,
      suggestions: [],
      term: "",
    }),
    [fetchGlobalSearchSuggestions.success]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  }
);
